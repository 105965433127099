import React, {useEffect, useState} from 'react';
import LoginInput from "./LoginInput/LoginInput";
import {ThreeDots} from 'react-loader-spinner';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {userStore} from './userstore';

const Login = () => {
    //==========from the userStore==============//
    const setUser = userStore((state) => state.setUser)
    const login = userStore((state) => state.login);
    const resetPassword = userStore((state) => state.submitNewPasswordRequest);
    //==========from the userStore==============//

    //==========Begin local state ==============//
    const [loginIsValid, setLoginIsValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [input, setInput] = useState({
        email: '',
        passWord: '',
        resetEmail: ''
    });

    const [loginForm, setLoginForm] = useState(true);
    //==========End local state ==============//

    //==========Begin User Name==============//
    const [userNameTouched, setUserNameTouched] = useState(false)
    const enteredUserNameIsValid = input.email.trim() !== '';
    const userNameIsInvalid = !enteredUserNameIsValid && userNameTouched;

    const userNameChangeHandler = event => {
        setInput({...input, email: event.target.value});
    }
    const userNameBlurHandler = () => {
        setUserNameTouched(true);
    }
    //==========End User Name==============//

    //==========Begin Password==============//
    const [passWordTouched, setPassWordTouched] = useState(false);
    const enteredPassWordIsValid = input.passWord.length > 7;
    const passWordIsInvalid = !enteredPassWordIsValid && passWordTouched;

    const passwordVisibleHandler = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    }

    const passWordChangeHandler = event => {
        setInput({...input, passWord: event.target.value});
    }
    const passwordBlurHandler = () => {
        setPassWordTouched(true);
    }
    //==========End Password==============//

    //==========Begin New Password==============//
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [resetEmailTouched, setResetEmailTouched] = useState(false);
    const resetEmailIsValid = emailPattern.test(input.resetEmail);
    const resetEmailIsInvalid = !resetEmailIsValid && resetEmailTouched;

    const resetEmailChangeHandler = event => {
        console.log("resetEmailChangeHandler")
        setInput({...input, resetEmail: event.target.value});
    }
    //    touched only when exit the input field
    const resetEmailBlurHandler = () => {
        console.log("resetEmailBlurHandler");
        setResetEmailTouched(true);
    }
    //==========End New Password==============//

    //==========Begin Reset Password Form Logic==============//
    const [resetIsValid, setResetIsValid] = useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [loading] = useState(false);

    const resetReset = () => {
        setInput({...input, resetEmail: ''});
        setUserNameTouched(false);
        setResetEmailTouched(false);
        setLoginForm(true);
        setResetIsValid(false);
    }

    const handleCancelReset = (event) => {//This is for the cancellation of the reset.
        event.preventDefault();
        resetReset();
    }

//    const resetEmailBlurHandler = () => {
//        console.log("resetEmailBlurHandler");
//        setResetEmailTouched(true);
//    }

    const handleResetPassWordRequest = (event) => {
        event.preventDefault();
        setShowSpinner(true);
        event.preventDefault();
        console.log("handleResetPassWordRequest");

        const t = resetPassword(input).then(response => {
            console.log(response == null);
            if(response == null) {
                setShowSpinner(false);
            } else if(!response.ok) {
                console.log("OEPS");
                toast.error("Make sure you have the correct email address also used in Nteger.");
            } else {
                console.log("YIHAAAA");
                toast.success("The reset email has been send, you should receive within seconds.");

            }
            setShowSpinner(false);
        });
    }

    //==========End Reset Password Form Logic==============//

    //==========Begin Form Logic==============//

    useEffect(() => {
        console.log("useEffect");
        if (enteredUserNameIsValid && enteredPassWordIsValid) {
            setLoginIsValid(true)
        } else {
            setLoginIsValid(false)
        }

        if(resetEmailIsValid){
            setResetIsValid(true)
        }
    }, [enteredUserNameIsValid, enteredPassWordIsValid, resetEmailIsValid]);

    const loginSubmitHandler = async (event) => {
        event.preventDefault();
        setUser(input.email);
        const loginInput = {
            email: '',
            passWord: ''
        }
        loginInput.email = input.email;
        loginInput.passWord = input.passWord;
        login(loginInput);
    }

    const handleGoToPwdReset = (event) => {//This is to go to the reset form
        event.preventDefault();
        setLoginForm(false);
    }

    const LoadingIndicator = props => {
        return (
        <ThreeDots
            height="30"
            width="30"
            color='#b4c1e4'
            ariaLabel='loading'
          />
        );
    }

    //==========End Form Logic==============//

    return (
        <div className="login__form">
            {loginForm && //It is the login form
                <form>
                    <LoginInput
                        label="User Name"
                        placeHolder="User Name*"
                        type="email"
                        id="user-name"
                        changeHandler={userNameChangeHandler}
                        blurHandler={userNameBlurHandler}
                        value={input.email}
                        isInValid={userNameIsInvalid}
                        errorMessage="User Name is mandatory"
                        isPassword={false}
                    />
                    <LoginInput
                        label="Password"
                        placeHolder="Password*"
                        type="password"
                        id="password"
                        changeHandler={passWordChangeHandler}
                        blurHandler={passwordBlurHandler}
                        value={input.passWord}
                        isInValid={passWordIsInvalid}
                        errorMessage="Password must be at least 8 character long"
                        isPassword={true}
                        clickOnShowHide={passwordVisibleHandler}
                        show={showPassword}
                    />
                    {/*Submit button*/}
                    <div className="login__row u-margin-top-small">
                        <button className="login__btn login__column"
                                onClick={handleGoToPwdReset}>Reset Password
                        </button>
                        <button disabled={!loginIsValid} className="login__btn login__column"
                                onClick={loginSubmitHandler}>Login
                        </button>
                    </div>
                    {/*Submit button*/}
                </form>}
            {!loginForm && //It is the reset password form
                <form>
                    <LoginInput
                        label="username/email"
                        placeHolder="Send reset mail to..."
                        type="email"
                        id="reset-email"
                        changeHandler={resetEmailChangeHandler}
                        blurHandler={resetEmailBlurHandler}
                        value={input.resetEmail}
                        isInValid={resetEmailIsInvalid}
                        errorMessage="Must be valid email address"
                        isPassword={false}
                    />
                    <div className="login__row u-margin-top-small">
                        <button
                            className="login__btn"
                            onClick={handleCancelReset}>Cancel
                        </button>
                        <button
                            disabled={!resetIsValid}
                            className="login__btn"
                            onClick={handleResetPassWordRequest}>Reset Password

                        </button>
                        {showSpinner ? <LoadingIndicator/> : null}
                    </div>
                </form>
            }
            <div>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                />
            </div>
        </div>
    )
}

export default Login;